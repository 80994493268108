import { useEffect } from 'react';
import parseCookies from './src/utils/parse-cookies';

export const onClientEntry = () => {
  if (window.ga) {
    window.ga('set', 'dimension1', process.env.GATSBY_BRANCH);

    const cookies = parseCookies();
    if (cookies.appBranch) {
      window.ga('set', 'dimension2', cookies.appBranch);
    }
  }

  const isDev = !window.location.host.includes('wzrd.ai');
  const oneWeekFromNow = new Date(Date.now() + (7 * 24 * 3600 * 1000));
  document.cookie = `siteBranch=${process.env.GATSBY_BRANCH}${isDev ? '' : `;domain=.wzrd.ai;path=/;SameSite=Lax;expires=${oneWeekFromNow.toUTCString()}`}`;
};
